import { useState } from 'react';
import { BACKEND_API_URL } from './Constants';
const Admin = () => {
    const [uploadingStatus, setUploadingStatus] = useState("")
    const [UploadType, setUploadType] = useState("")
    const [formVales, setFromValues] = useState({})
    const adminCustomerSupportUrl = `${BACKEND_API_URL}/api/engagematic/v1/admin-customer-support`
    const adminAnalyticsUrl = `${BACKEND_API_URL}/api/engagematic/v1/admin-analytics`
    const UploadCustomerSupport = async (e) => {
        e.preventDefault();
        setUploadingStatus("")
        console.log(formVales)
        const formData = new FormData()
        console.log(formVales.uploaded_files, formVales.url)
        if (formVales?.uploaded_files) {
            const uploaded_files = formVales.uploaded_files
            for (let i = 0; i < uploaded_files.length; i++) {
                formData.append('uploaded_files', uploaded_files[i]);
            }
        }
        if (formVales?.url) {
            formData.append('url', formVales.url)
        }

        console.log("FormData")
        console.log(formData)
        try {
            const response = await fetch(adminCustomerSupportUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                },
                body: formData
            })
            if (response.ok) {
                const data = await response.json()
                setUploadingStatus("Request sent Successfully")
                console.log(data)
            }
            else {
                setUploadingStatus("Error occured while sending request")
            }
        }
        catch (e) {
            console.error()
        }
    }
    const UploadFile = async (e) => {
        setUploadingStatus("File Uploading")
        localStorage.setItem("fileType", "other")
        console.log(e.target.files)
        const fileType = e.target.files[0].name.split('.').pop()
        const formData = new FormData()
        const files = e.target.files
        for (let i = 0; i < files.length; i++) {
            formData.append('uploaded_files', files[i]);
        }

        try {
            const response = await fetch(adminAnalyticsUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                },
                body: formData
            })
            if (response.ok) {
                const data = await response.json()
                setUploadingStatus("File Uploaded Successfully")
                console.log(data)
            }
            else {
                setUploadingStatus("Error occured while uploading")
            }
        }
        catch (e) {
            console.error()
        }

    }
    return <>
        <div class="mb-3">
            {UploadType === "production" && <><form><label for="formFile" class="form-label">Upload docs/txt/pdf file here</label>
                <p></p>
                <input class="form-control-sm" type="file" multiple id="formFile" onChange={(e) => setFromValues({ ...formVales, "uploaded_files": e.target.files })} />
                <p>{uploadingStatus}</p>
                <label for="formFile" class="form-label">Scrape using url from here</label>
                <p></p>
                <input class="form-control-sm" type="text" id="formFile" onChange={(e) => setFromValues({ ...formVales, "url": e.target.value })} />
                <p></p>
                <button class="m-4 p-2 btn btn-primary" onClick={UploadCustomerSupport}>submit</button>
            </form></>}
            {UploadType === "analytics" && <><label for="formFile" class="form-label">Upload csv file here</label>
                <p></p>
                <input class="form-control-sm" type="file" multiple id="formFile" onChange={UploadFile} />
                <p>{uploadingStatus}</p> </>}
            {UploadType === "" && <><p class="m-0 p-2">
                <button class="m-4 p-2 btn btn-primary" onClick={(e) => setUploadType("production")}>Customer Support</button>
                <button class="m-0 p-2 btn btn-primary" onClick={(e) => setUploadType("analytics")}>Analytics</button>
            </p></>}
        </div>
    </>
}

export default Admin