import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Admin from './Admin'
import UserProduction from './UserProduction';
import UserAnalytics from './UserAnalytics';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<UserProduction />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/analytics" element={<UserAnalytics />} />
      </Routes>
    </>
  );
}
export default App;
