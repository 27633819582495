import { useState } from 'react';
import { BACKEND_API_URL } from './Constants';
const UserProduction = () => {
    const [queryTxt, setQueryTxt] = useState("")
    const [outputStr, setOutputStr] = useState("")
    const userCustomerSupportUrl = `${BACKEND_API_URL}/api/engagematic/v1/user-customer-support`

    const getResult = async (e) => {
        const payload = {
            "query_text": queryTxt
        }
        console.log("payload", payload)
        try {
            const response = await fetch(userCustomerSupportUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)


            })
            if (response.ok) {
                const data = await response.json()
                console.log("data", data)
                setOutputStr(data.answer)
            }
        }
        catch (e) {
            console.error(e)
        }

    }
    return <>
        <div className="mb-3">
            <label for="exampleInputEmail1" className="form-label-sm">Enter Your Query</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setQueryTxt(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary" onClick={getResult}>Submit</button>
        <div className="form-group">
            <label for="exampleFormControlTextarea1">Your Answer will appear here</label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" value={outputStr} disabled></textarea>
        </div>

    </>

}

export default UserProduction